import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import CartBuzv from "./CartBuzv"

const MainBuzv = props => {
  const { arg, val, session_id } = props

  return (
    <div className="grid grid-cols-1 gap-0 mx-auto my-0 max-w-screen-lg">
      <div className="img_box">
        <StaticImage
          width={1200}
          height={197}
          src={"../assets/images/buzv/01_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"01_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={196}
          src={"../assets/images/buzv/01_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"01_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={197}
          src={"../assets/images/buzv/01_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"01_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={196}
          src={"../assets/images/buzv/01_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"01_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={197}
          src={"../assets/images/buzv/01_05.png"}
          placeholder="blurred"
          quality="65"
          alt={"01_05.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={196}
          src={"../assets/images/buzv/01_06.png"}
          placeholder="blurred"
          quality="65"
          alt={"01_06.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={197}
          src={"../assets/images/buzv/01_07.png"}
          placeholder="blurred"
          quality="65"
          alt={"01_07.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={196}
          src={"../assets/images/buzv/01_08.png"}
          placeholder="blurred"
          quality="65"
          alt={"01_08.png"}
        />
      </div>

      <div className="img_box">
        <StaticImage
          width={1200}
          height={271}
          src={"../assets/images/buzv/F_09_02_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_02_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={271}
          src={"../assets/images/buzv/F_09_02_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_02_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={271}
          src={"../assets/images/buzv/F_09_02_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_02_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={270}
          src={"../assets/images/buzv/F_09_02_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_02_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={271}
          src={"../assets/images/buzv/F_09_02_05.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_02_05.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={271}
          src={"../assets/images/buzv/F_09_02_06.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_02_06.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={321}
          src={"../assets/images/buzv/F_09_03_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_03_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={321}
          src={"../assets/images/buzv/F_09_03_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_03_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={320}
          src={"../assets/images/buzv/F_09_03_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_03_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={321}
          src={"../assets/images/buzv/F_09_03_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_03_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={321}
          src={"../assets/images/buzv/F_09_03_05.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_03_05.png"}
        />
      </div>

      <CartBuzv arg={arg} val={val} session_id={session_id} />

      <div className="img_box">
        <StaticImage
          width={1200}
          height={133}
          src={"../assets/images/buzv/F_09_05_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_05_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={134}
          src={"../assets/images/buzv/F_09_05_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_05_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={133}
          src={"../assets/images/buzv/F_09_05_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_05_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={134}
          src={"../assets/images/buzv/F_09_05_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_05_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={133}
          src={"../assets/images/buzv/F_09_05_05.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_05_05.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={166}
          src={"../assets/images/buzv/F_09_06_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_06_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={166}
          src={"../assets/images/buzv/F_09_06_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_06_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={166}
          src={"../assets/images/buzv/F_09_06_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_06_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={166}
          src={"../assets/images/buzv/F_09_06_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_06_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={166}
          src={"../assets/images/buzv/F_09_06_05.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_06_05.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={206}
          src={"../assets/images/buzv/F_09_07_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_07_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={206}
          src={"../assets/images/buzv/F_09_07_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_07_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={205}
          src={"../assets/images/buzv/F_09_07_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_07_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={206}
          src={"../assets/images/buzv/F_09_07_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_07_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={206}
          src={"../assets/images/buzv/F_09_07_05.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_07_05.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={273}
          src={"../assets/images/buzv/F_09_08_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_08_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={272}
          src={"../assets/images/buzv/F_09_08_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_08_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={273}
          src={"../assets/images/buzv/F_09_08_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_08_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={272}
          src={"../assets/images/buzv/F_09_08_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_08_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={273}
          src={"../assets/images/buzv/F_09_08_05.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_08_05.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={272}
          src={"../assets/images/buzv/F_09_08_06.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_08_06.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={273}
          src={"../assets/images/buzv/F_09_08_07.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_08_07.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={142}
          src={"../assets/images/buzv/F_09_09_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_09_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={142}
          src={"../assets/images/buzv/F_09_09_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_09_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={142}
          src={"../assets/images/buzv/F_09_09_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_09_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={142}
          src={"../assets/images/buzv/F_09_09_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_09_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={142}
          src={"../assets/images/buzv/F_09_09_05.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_09_05.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={132}
          src={"../assets/images/buzv/F_09_10_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_10_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={131}
          src={"../assets/images/buzv/F_09_10_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_10_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={132}
          src={"../assets/images/buzv/F_09_10_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_10_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={131}
          src={"../assets/images/buzv/F_09_10_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_10_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={132}
          src={"../assets/images/buzv/F_09_10_05.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_10_05.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={131}
          src={"../assets/images/buzv/F_09_10_06.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_10_06.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={132}
          src={"../assets/images/buzv/F_09_10_07.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_10_07.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={131}
          src={"../assets/images/buzv/F_09_10_08.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_10_08.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={259}
          src={"../assets/images/buzv/F_09_11_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_11_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={258}
          src={"../assets/images/buzv/F_09_11_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_11_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={259}
          src={"../assets/images/buzv/F_09_11_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_11_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={259}
          src={"../assets/images/buzv/F_09_11_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_11_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={259}
          src={"../assets/images/buzv/F_09_11_05.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_11_05.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={258}
          src={"../assets/images/buzv/F_09_11_06.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_11_06.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={259}
          src={"../assets/images/buzv/F_09_11_07.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_11_07.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={413}
          src={"../assets/images/buzv/F_09_12_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_12_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={412}
          src={"../assets/images/buzv/F_09_12_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_12_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={413}
          src={"../assets/images/buzv/F_09_12_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_12_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={412}
          src={"../assets/images/buzv/F_09_12_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_12_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={413}
          src={"../assets/images/buzv/F_09_12_05.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_12_05.png"}
        />
      </div>

      <CartBuzv arg={arg} val={val} session_id={session_id} />

      <style jsx="true">
        {`
          .img_box > div {
            overflow: hidden;
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;
            width: 100%;
            margin: 0 auto -1px;
            padding: 0;
          }
          .img_box > div > img {
            width: 100%;
            max-width: 100%;
          }
        `}
      </style>
    </div>
  )
}
export default MainBuzv
