import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Config } from "../../site.config"
import CartCvImage from "../assets/images/buzv/F_09_04_05.png"

const CartBuzv = props => {
  const { arg, val, session_id } = props

  return (
    <>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={177}
          src={"../assets/images/buzv/F_09_04_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_04_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={410}
          src={"../assets/images/buzv/F_09_04_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_04_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={965}
          src={"../assets/images/buzv/F_09_04_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_04_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={1200}
          height={261}
          src={"../assets/images/buzv/F_09_04_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"F_09_04_04.png"}
        />
      </div>

      <form
        className="m-0 p-0 align-bottom leading-none"
        style={{ margin: "0 auto -2px" }}
        name="product_form"
        method="post"
        action={process.env.CART_URL}
      >
        <input
          src={CartCvImage}
          type="image"
          className="w-full max-w-screen-lg m-0 p-0"
          alt="ご注文はコチラ"
        />

        <input type="hidden" name="q" value="1" />
        <input type="hidden" name="m" value="1" />
        <input type="hidden" name="h" value={Config.productId} />
        <input type="hidden" name="i" value={Config.productInfo[0].id} />
        <input type="hidden" name="company_id" value={Config.companyId} />
        <input
          type="hidden"
          name="deliver_trader_id"
          value={Config.deliverTraderId}
        />
        <input type="hidden" name="argument_id" value={arg} />
        <input type="hidden" name="argument_data" value={val} />
        <input type="hidden" name="SESSION" value={session_id} />
      </form>
    </>
  )
}

export default CartBuzv
